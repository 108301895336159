import { RestService } from "@/app/core/services/core/RestService";
import { Mc } from "@/app/core/models/domain/Mc";

export class McService extends RestService<Mc> {
  constructor() {
    super("/mcs", "mc", "r");
  }

  async checkMc(mc: string): Promise<Mc> {
    return await this.httpGet<Mc>(`${this.apiHref()}/${mc}/check-mc`);
  }

  async sendMc(mc: string, files: FormData): Promise<boolean> {
    return await this.httpPost(`${this.apiHref()}/${mc}/send-mc`, files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
